import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";

// import svgCog from "../images/svg/cog-primary-icon.svg";
// import svgPuzzle from "../images/svg/puzzle-primary-icon.svg";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <Hero
      desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
      desktop={data.bgDesktop.childImageSharp.fluid.src}
      tablet={data.bgTablet.childImageSharp.fluid.src}
      mobile={data.bgMobile.childImageSharp.fluid.src}
      mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
    >
      <div className="mb-6">
        <h1 className="display-3 font-size-48--md-down font-weight-medium text-white mb-3">
          Static Power
        </h1>
        <p className="lead text-light">
          Your partner in all your electrical needs.
        </p>
      </div>

      <a
        className="js-go-to u-icon u-icon--light u-icon--md rounded-circle"
        href="#StrategicAlliance"
      >
        <span className="fa fa-angle-down u-icon__inner text-purple font-size-18"></span>
      </a>
    </Hero>

    <div id="StrategicAlliance" className="container space-1 space-2--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <span className="u-label u-label--sm u-label--purple mb-3">
          Strategic Alliance
        </span>
        <h2 className="h3">Our Partners</h2>
        <p>We work with the industry's top suppliers.</p>
      </div>
    </div>

    <div className="container space-2-bottom space-2-bottom--lg">
      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-4 mb-5 px-md-0 px-5 text-center">
          <Img
            className="w-sm-50 w-lg-75 mx-lg-auto mb-2"
            fluid={data.comap.childImageSharp.fluid}
            alt="COMAP Gen-set and Engine Controllers"
            title="COMAP Gen-set and Engine Controllers"
          />
          <p>Gen-set and Engine Controllers</p>
        </div>
        <div className="col-md-4 mb-5 px-md-0 px-5 text-center">
          <Img
            className="w-sm-50 w-lg-75 mx-lg-auto mb-2"
            fluid={data.mega.childImageSharp.fluid}
            alt="MEGADUCT busway"
            title="MEGADUCT busway"
          />
          <p>Manufacturer of busway trunking system</p>
        </div>
        <div className="col-md-4 mb-5 px-md-0 px-5 text-center">
          <Img
            className="w-sm-50 w-lg-75 mx-lg-auto mb-2"
            fluid={data.philips.childImageSharp.fluid}
            alt="Philips Lighting Signify"
            title="Philips Lighting Signify"
          />
          <p>High-quality, energy-efficient lighting</p>
        </div>
      </div>
    </div>

    <div id="WhatWeProvide" className="container space-1 space-2--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <span className="u-label u-label--sm u-label--red mb-3">
          What We Provide
        </span>
        <h2 className="h3">Our Products and Services</h2>
        <p>
          We pride ourselves in giving our customers the best products and
          services in industry.
        </p>
      </div>
    </div>

    <div className="container space-2-bottom space-3-bottom--lg">
      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 mb-5 mx-2 mx-md-0">
          <h3>Products</h3>
          <ul>
            <li>Busway System</li>
            <li>Synchronizing Panel</li>
            <li>Commercial and Industrial Automation System</li>
            <li>Generator Set Control Panels</li>
            <li>Automatic/Manual Transfer Switch</li>
            <li>Motor Control Center</li>
            <li>Distribution Boards</li>
          </ul>
        </div>
        <div className="col-md-6 mb-5 mx-2 mx-md-0">
          <h3>Services</h3>
          <ul>
            <li>Engineering Consultancy</li>
            <li>System Design and Upgrading</li>
            <li>Preventive Maintenance</li>
            <li>Application Assistance</li>
            <li>Technical Services and Support</li>
            <li>Project Management and Coordination</li>
            <li>
              Rehabilitation and Troubleshooting of Generators and Synchronizing
              Panels
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "jp.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "jp.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "jp.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "jp.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "jp-sm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    comap: file(relativePath: { eq: "comap/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mega: file(relativePath: { eq: "megaduct/MEGADUCT-LOGO.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    philips: file(relativePath: { eq: "philips/signify-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
