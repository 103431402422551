import React, { Fragment } from "react";

const Hero = ({ desktopWide, desktop, tablet, mobile, mobileXS, children }) => {
  return (
    <Fragment>
      <div className="d-md-flex align-items-md-center height-100vh--lg gradient-overlay-half-dark-v5 bg-img-hero hero">
        <div className="container space-2 space-3--md text-center">
          <div className="w-md-75 w-lg-60 mt-lg-9 mx-md-auto">{children}</div>
        </div>
      </div>

      <style jsx="true">{`
        .hero {
          background-image: url(${desktopWide});
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          .hero {
            background-image: url(${desktop});
          }
        }
        @media (min-width: 768px) and (max-width: 992px) {
          .hero {
            background-image: url(${tablet});
          }
        }
        @media (min-width: 576px) and (max-width: 768px) {
          .hero {
            background-image: url(${mobile});
          }
        }
        @media (max-width: 576px) {
          .hero {
            background-image: url(${mobileXS});
          }
        }
      `}</style>
    </Fragment>
  );
};

export default Hero;
